import 'script-loader!node_modules/slugify/index.js';
import 'imports-loader?global=>window!./soyutils.js?no-babel';
import { Funcs } from './funcs.js';
import { Directives } from './directives.js';

export function InitSoy2JS (yxt) {
  yxt.Pages = {};
  yxt.Pages.Soy = {};
  yxt.Pages.Soy.Funcs = Funcs;
  yxt.Pages.Soy.Directives = Directives;
  return yxt;
}
