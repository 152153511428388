export const ImageObjectFit = function () {
  if (!('CSS' in window && CSS.supports('object-fit', 'cover'))) {
    const imageEls = document.querySelectorAll('.ObjectFit');

    for (const image of imageEls) {
      const parentEl = image.parentNode;
      const src = image.src;
      parentEl.classList.add('ObjectFit-container');
      parentEl.style['background-image'] = `url(${src})`;
    }
  }
};
